import React from 'react';

const About = () => {
    return (
        <div>
            <h2>Acerca de Cubysoft</h2>
            <p>Somos una empresa enfocada en servicios de servidores y dominios accesibles.</p>
        </div>
    );
};

export default About;
