import React from 'react';
import '../style/content.css'

const SpaceContent = () => {
    return (
        <div className='space_content'>
        </div>
    );
};

export default SpaceContent;
