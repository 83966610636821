import React from 'react';
import '../style/content.css'

const Space = () => {
    return (
        <div className='content_space'>
        </div>
    );
};

export default Space;
