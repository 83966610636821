import React from 'react';

const Services = () => {
    return (
        <div>
            <h2>Nuestros Servicios</h2>
            <ul>
                <li>Registro de dominios</li>
                <li>Servidores web</li>
                <li>Almacenamiento en la nube</li>
            </ul>
        </div>
    );
};

export default Services;
